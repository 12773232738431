import React from "react";
import { Layout, Seo } from "components/common";
import {Intro, Papers, Books, About} from "components/landing";

const Home = () => (
	<Layout lang="cn" >
		<Seo lang="cn" title={"蒋韬"}/>
		<Intro lang="cn" />
		<Books lang="cn" />
		<Papers lang="cn" />
		<About lang="cn" />
	</Layout>
);

export default Home;
